import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import { useNavigate, useSearchParams } from "react-router-dom";
import CityList from "./CityList";

const CityPage = observer(() => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const city = params.get("city");

  const [active_tab, setActiveTab] = useState("tab1");
  return (
    <>
      <HeaderTitle title="THIÊN ĐỊA MỸ NHÂN" />
      <div className="main-content">
        <div className="page-content">
          <div className="beauty-container">
            <div className="van-tabs">
              <div className="van-tabs__nav">
                <div
                  className={classNames("van-tab", {
                    active: active_tab === "tab1",
                  })}
                  onClick={() => navigate(`/beauty/city`)}
                >
                  <p>Tỉnh/Thành Phố</p>
                </div>
                <div
                  className={classNames("van-tab", {
                    active: active_tab === "tab2",
                  })}
                  onClick={() => navigate(`/beauty?tab=tab2`)}
                >
                  <p>Giới thiệu nền tảng</p>
                </div>
              </div>
            </div>
            <p className="des">
              Lưu ý: Nền tảng ứng dụng hẹn hò thông qua trực tuyến. Để đảm bảo
              quyền riêng tư cá nhân của người dùng, chỉ có thể tham gia với tư
              cách thành viên bằng cách liên hệ với chuyên viên để được sắp xếp
              lịch hẹn hò thành công sớm nhất.
            </p>
            <CityList onMore={() => setActiveTab("tab2")} />
          </div>
        </div>
      </div>
    </>
  );
});

export default CityPage;
