import React from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

function HeaderTitle({ isBack = true, title, subTitle, isSave, onSave }) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return (
    <div className="home-top-bar">
      {isBack && (
        <span className="btn_back" onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faAngleLeft} />
        </span>
      )}
      <h1 className={classNames("bg_header_name")}>{title}</h1>
      {isSave && (
        <span className="btn_save" onClick={() => onSave()}>
          Lưu
        </span>
      )}
      {subTitle && <div className="right">{subTitle}</div>}
    </div>
  );
}

export default HeaderTitle;
