export const optionBank = [
  {
    value: "ACB",
    sortName: "ACB",
    text: "Ngân hàng TMCP Á Châu",
  },
  {
    value: "MBbank",
    sortName: "MBbank",
    text: "Ngân hàng quân đội",
  },
  {
    value: "TPBank",
    sortName: "TPBank",
    text: "Ngân hàng TMCP Tiên Phong",
  },
  {
    value: "DAB",
    sortName: "DAB",
    text: "Ngân hàng TMCP Đông Á",
  },
  {
    value: "SeABank",
    sortName: "SeABank",
    text: "Ngân Hàng TMCP Đông Nam Á",
  },
  {
    value: "ABBANK",
    sortName: "ABBANK",
    text: "Ngân hàng TMCP An Bình",
  },
  {
    value: "BacABank",
    sortName: "BacABank",
    text: "Ngân hàng TMCP Bắc Á",
  },
  {
    value: "VietCapitalBank",
    sortName: "VietCapitalBank",
    text: "Ngân hàng TMCP Bản Việt",
  },
  {
    value: "MSB",
    sortName: "MSB",
    text: "Ngân hàng TMCP Hàng hải Việt Nam",
  },
  {
    value: "TCB",
    sortName: "Techcombank",
    text: "Ngân hàng TMCP Kỹ Thương Việt Nam",
  },
  {
    value: "KienLongBank",
    sortName: "KienLongBank",
    text: "Ngân hàng TMCP Kiên Long",
  },
  {
    value: "NamA",
    sortName: "Nam A Bank",
    text: "Ngân hàng TMCP Nam Á",
  },
  {
    value: "VPBank",
    sortName: "VPBank",
    text: "Ngân hàng TMCP Việt Nam Thịnh Vượng",
  },
  {
    value: "HDBank",
    sortName: "HDBank",
    text: "Ngân hàng TMCP Phát triển Thành phố Hồ Chí Minh",
  },
  {
    value: "OCB",
    sortName: "OCB",
    text: "Ngân hàng TMCP Phương Đông",
  },
  {
    value: "PVcombank",
    sortName: "PVcombank",
    text: "Ngân hàng TMCP Đại chúng",
  },
  {
    value: "VIB",
    sortName: "VIB",
    text: "Ngân hàng TMCP Quốc tế Việt Nam",
  },
  {
    value: "SCB",
    sortName: "SCB",
    text: "Ngân hàng TMCP Sài Gòn",
  },
  {
    value: "SGB",
    sortName: "Saigonbank",
    text: "Ngân hàng TMCP Sài Gòn Công Thương",
  },
  {
    value: "SHB",
    sortName: "SHBank",
    text: "Ngân hàng TMCP Sài Gòn – Hà Nội",
  },
  {
    value: "STB",
    sortName: "Sacombank",
    text: "Ngân hàng TMCP Sài Gòn Thương Tín",
  },
  {
    value: "VAB",
    sortName: "VietABank",
    text: "Ngân hàng TMCP Việt Á",
  },
  {
    value: "BVB",
    sortName: "BaoVietBank",
    text: "Ngân hàng TMCP Bảo Việt",
  },
  {
    value: "VietBank",
    sortName: "VietBank",
    text: "Ngân hàng TMCP Việt Nam Thương Tín",
  },
  {
    value: "Eximbank",
    sortName: "Eximbank",
    text: "Ngân Hàng TMCP Xuất Nhập khẩu Việt Nam",
  },
  {
    value: "LienVietPostBank",
    sortName: "LienVietPostBank",
    text: "Ngân Hàng TMCP Bưu điện Liên Việt",
  },
  {
    value: "Vietcombank",
    sortName: "Vietcombank",
    text: "Ngân Hàng TMCP Ngoại thương Việt Nam",
  },
  {
    value: "VietinBank",
    sortName: "VietinBank",
    text: "Ngân Hàng TMCP Công Thương Việt Nam",
  },
  {
    value: "BIDV",
    sortName: "BIDV",
    text: "Ngân Hàng TMCP Đầu tư và Phát triển Việt Nam",
  },
  {
    value: "Oceanbank",
    sortName: "Oceanbank",
    text: "Ngân hàng Thương mại TNHH MTV Đại Dương",
  },
  {
    value: "GPBank",
    sortName: "GPBank",
    text: "Ngân hàng Thương mại TNHH MTV Dầu Khí Toàn Cầu",
  },
  {
    value: "Agribank",
    sortName: "Agribank",
    text: "Ngân hàng Nông nghiệp và Phát triển Nông thôn Việt Nam",
  },
  {
    value: "HSBC",
    sortName: "HSBC",
    text: "Ngân hàng TNHH MTV HSBC",
  },
  {
    value: "Citibank",
    sortName: "Citibank",
    text: "Ngân hàng Citibank Việt Nam",
  },
  {
    value: "Shinhan",
    sortName: "Shinhan",
    text: "Ngân hàng TNHH MTV Shinhan Việt Nam",
  },
];
