import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";

import bankIcon from "assets/img/icon/bank.png";
import { Link, useNavigate } from "react-router-dom";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import Collapse from "_common/component/Collapse";
import { formatNumber } from "_common/utils/formatValue";
import classNames from "classnames";
import { NotificationManager } from "react-notifications";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { PaymentApi } from "states/api/payment";

const DepositFormPage = observer(() => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    authStore: { user, user_wallet, general },
  } = useStores();

  const [amount, setAmount] = useState("");
  const [selectAmount, setSelectAmount] = useState("");

  const handleChangeInput = (event) => {
    const value = event?.target?.value;
    const format = value?.replaceAll(",", "")?.replaceAll(".", "");
    setAmount(Number(format));
  };

  const handleSubmit = () => {
    if (amount < Number(general?.min_deposit || 0))
      return NotificationManager.error(
        `Vui lòng nhập số tiền lớn hơn ${formatNumber(
          general?.min_deposit
        )}đVND`
      );
    if (amount > Number(general?.max_deposit || 0))
      return NotificationManager.error(
        `Vui lòng nhập số tiền nhỏ hơn ${formatNumber(
          general?.min_deposit
        )}đVND`
      );
    navigate(`/payment/deposit/bank-info?money=${amount}`);
    return null;
  };

  const handleSelect = (value) => {
    setSelectAmount(value);
    setAmount(Number(value));
  };

  return (
    <>
      <HeaderTitle title="Nạp tiền" />

      <div className="payment-container payment-form-page">
        <div className="payType">
          <div className="title-cell">
            <img src={bankIcon} alt="" />
            <span> Vui lòng liên hệ bộ phận chăm sóc khách hàng </span>
          </div>
          <div className="description">
            <p> Vui lòng liên hệ bộ phận chăm sóc khách hàng </p>
          </div>
        </div>
        <div className="payMoney">
          <div className="label">
            <span>Số tiền nạp:</span>
            <span>Vui lòng nhập số tiền</span>
          </div>
          <div className="money">
            <span>₫</span>
            <input
              inputMode="numeric"
              pattern="[0-9]"
              placeholder="Nhập số tiền"
              className="control-number"
              value={amount ? formatNumber(amount) : ""}
              onChange={handleChangeInput}
            />
            <span className="fieldMoney">K</span>
          </div>
          <div className="remark">
            <p>
              Số tiền nạp mỗi lần lớn nhất {formatNumber(general?.max_deposit)}
              KVND
            </p>
            <p>
              Số tiền nạp mỗi lần nhỏ nhất {formatNumber(general?.min_deposit)}
              KVND
            </p>
          </div>
          <div className="moneyNumber">
            <button
              type="button"
              onClick={() => handleSelect(1000)}
              className={classNames("btn", { active: selectAmount === 1000 })}
            >
              1000KVND
            </button>
            <button
              type="button"
              onClick={() => handleSelect(5000)}
              className={classNames("btn", { active: selectAmount === 5000 })}
            >
              5000KVND
            </button>
            <button
              type="button"
              onClick={() => handleSelect(1000000000)}
              className={classNames("btn", {
                active: selectAmount === 1000000000,
              })}
            >
              1000000000KVND
            </button>
          </div>
          <div className="balance">
            <span>Số dư:</span>
            <span className="text-red">
              {formatNumber(user_wallet?.amount)}K
            </span>
            <span>VND</span>
          </div>
          <div className="service">
            <span> Nếu có vấn đề,Vui lòng liên hệ</span>
            <Link to="/">Dịch vụ trực tuyến</Link>
          </div>
          <button
            type="button"
            className={classNames("btn btn-sub", {
              active: Number(amount || 0) >= Number(general?.min_deposit || 0),
            })}
            onClick={handleSubmit}
          >
            Bước tiếp theo
          </button>
        </div>
      </div>
    </>
  );
});

export default DepositFormPage;
