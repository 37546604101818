import React from "react";

import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import NoData from "_common/component/NoData";
import { useStores } from "_common/hooks";

function MovieHistoryPage() {
  const {
    authStore: { user },
  } = useStores();

  return (
    <>
      <HeaderTitle title="Phim của tôi" />
      <div className="container_main">
        <div className="histories ">
          <div className="account-card">
            <div className="left">
              <img src={user?.avatar_url} alt="" />
              <div>
                <h5>
                  Hi, {user?.nick_name || user?.full_name || user?.username}
                </h5>
                <p>Hoàn thành nhiệm vụ để nhận thêm vé xem phim</p>
              </div>
            </div>
            <div className="right">
              <button type="button" className="btn-ticket">
                Vé xem phim: 0
              </button>
            </div>
          </div>
          <h3 className="title" style={{ marginTop: 15 }}>
            Lịch sử quy đổi
          </h3>
          <NoData description="Không có dữ liệu" />
        </div>
      </div>
    </>
  );
}

export default MovieHistoryPage;
