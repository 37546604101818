import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useStores } from "_common/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { useQuery } from "@tanstack/react-query";
import { MovieApi } from "states/api/movie";

export default function HotVideo() {
  const navigate = useNavigate();
  const {
    authStore: { video_hot },
  } = useStores();

  const { data, refetch } = useQuery(
    ["movie", "list_hot"],
    () =>
      MovieApi.getList({
        params: {
          page: 1,
          limit: 6,
          type: "hot",
        },
      }),
    {
      staleTime: 300000,
    }
  );

  return (
    <div className="hot-recommend">
      <div className="hot-title">
        <h2>Phổ biến nhất</h2>
        <div className="action">
          <span onClick={() => navigate("/movie")}>Xem thêm</span>
          <FontAwesomeIcon icon={faAngleRight} />
        </div>
      </div>
      <div className="slide-video">
        <Swiper
          spaceBetween={5}
          slidesPerView="auto"
          autoplay={{
            delay: 10000,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
          className="swiper2 "
        >
          {data?.data?.map((item) => (
            <SwiperSlide key={item?.id}>
              <Link to={`/movie/detail?code=${item?.id}`} key={item?.id}>
                <img alt="" src={item?.thumb_url} />
                <p className="movie-time">
                  {item?.name}
                  <span className="count-down">{item?.time}</span>
                </p>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}
