import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { useStores } from "_common/hooks";
import { formatNumber } from "_common/utils/formatValue";

import bankIcon from "assets/img/icon/bank.png";
import { Link, useNavigate } from "react-router-dom";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import Collapse from "_common/component/Collapse";

const DepositPage = observer(() => {
  const navigate = useNavigate();
  const {
    authStore: { user, user_wallet },
  } = useStores();

  return (
    <>
      <div className="header_member header_payment">
        <HeaderTitle
          title="Loại hình nạp tiền"
          subTitle={
            <span onClick={() => navigate(`/histories/deposit`)}>
              Lịch sử nạp tiền
            </span>
          }
        />
        <div className="user-wrapper">
          <div className="user-avatar">
            <img src={user?.avatar_url} alt="" />
          </div>
          <div className="user-info">
            <h3 className="username">{user?.username}</h3>
            <h3 className="balance">
              Số dư: {formatNumber(user_wallet?.amount)}KVND
            </h3>
          </div>
        </div>
      </div>
      <div className="payment-container">
        <Collapse>
          <Collapse.Panel
            title={
              <div className="title-cell">
                <img src={bankIcon} alt="" />
                <span>Thẻ ngân hàng</span>
              </div>
            }
          >
            <Link to="/payment/deposit/submit" style={{ marginBottom: 15 }}>
              <div className="title-cell">
                <img src={bankIcon} alt="" />
                <span> Vui lòng liên hệ bộ phận chăm sóc khách hàng </span>
              </div>
              <p> Vui lòng liên hệ bộ phận chăm sóc khách hàng </p>
            </Link>
          </Collapse.Panel>
        </Collapse>
      </div>
    </>
  );
});

export default DepositPage;
