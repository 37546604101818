export const paymentStatusText = {
  pending: "Đang chờ xem xét",
  complete: "Hoàn thành",
  error: "Từ chối",
  reject: "Từ chối",
};

export const paymentStatusColor = {
  pending: "#ff8c1a",
  complete: "green",
  error: "red",
};

export const gameStatusText = {
  betGame: "Đang cược",
  win: "Đã trúng thưởng",
  loss: "Chưa trúng thưởng",
};

export const gameTypeText = {
  code1: "A",
  code2: "B",
  code3: "C",
  code4: "D",
};

export const gameLevelText = {
  level_1: "Bình chọn 1",
  level_2: "Bình chọn 2",
  level_3: "Bình chọn 3",
};
