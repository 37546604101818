import React from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

function HeaderLogin({ isBack = true, title }) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return (
    <div className="bg_header ">
      {isBack && (
        <span className="btn_back" onClick={() => navigate(-1)}>
          {/* <FontAwesomeIcon icon={faAngleLeft} /> */}
        </span>
      )}
      <h1 className={classNames("bg_header_name")}>{title}</h1>
    </div>
  );
}

export default HeaderLogin;
