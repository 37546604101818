import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { cityList } from "_common/constants/city";

export default function CityList({ onMore }) {
  const navigate = useNavigate();

  return (
    <div className="city-container">
      <div className="list-city">
        {cityList.map((item, index) => (
          <div
            className="item"
            key={`city-${index.toString()}`}
            onClick={() => navigate(`/beauty?city=${item}`)}
          >
            <h5>{item}</h5>
          </div>
        ))}
        <div className="item" onClick={() => onMore()}>
          <h5 className="more">Mở khoá nhiều hơn</h5>
        </div>
      </div>
    </div>
  );
}
