import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useStores } from "_common/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeUp } from "@fortawesome/free-solid-svg-icons";

export default function Notice() {
  const navigate = useNavigate();
  const {
    authStore: { banners },
  } = useStores();

  return (
    <div className="notice-bar">
      <div className="wrapper">
        <div className="icon-notice">
          <FontAwesomeIcon icon={faVolumeUp} />
        </div>
        <div className="notice">
          <p>037***4545 vừa rút 600,000đ</p>
        </div>
      </div>
    </div>
  );
}
