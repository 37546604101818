import React, { useEffect, useMemo } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import { useStores } from "_common/hooks";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NotificationManager } from "react-notifications";
import { formatNumber } from "_common/utils/formatValue";
import { MovieApi } from "states/api/movie";
import { useQuery } from "@tanstack/react-query";

export default function ListVideoDetail() {
  const navigate = useNavigate();
  const {
    authStore: { videos },
  } = useStores();
  const [params] = useSearchParams();
  const code = params.get("code");

  const { data, refetch } = useQuery(
    ["movie", "detail", code],
    () =>
      MovieApi.getDetail({
        id: code,
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  const handleSubmit = () => {
    NotificationManager.warning("Tiếp tục xem liên hệ thầy");
  };

  return (
    <>
      <HeaderTitle title={data?.name} />
      <div className="main-content">
        <div className="page-content">
          <div className="video-container">
            <div className="video-div">
              <div className="video-js">
                <video
                  id="example-video_html5_api"
                  className="vjs-tech"
                  preload="auto"
                  crossOrigin="anonymous"
                  tabIndex="-1"
                  poster={data?.thumb_url}
                >
                  <track
                    default
                    kind="captions"
                    srcLang="en"
                    src={data?.video_url}
                  />
                </video>
                <img className="video-thumb" alt="" src={data?.thumb_url} />
                <div className="play-button" onClick={handleSubmit}>
                  <FontAwesomeIcon icon={faPlay} />
                </div>
              </div>
              <h1>{data?.name}</h1>
              <p>{formatNumber(data?.count)} Lần phát sóng</p>
            </div>
            <div className="movie-content">
              <div className="hot-title">
                <h2>Đề xuất phổ biến</h2>
              </div>
              <div className="movie-body">
                {data?.recommends.slice(0, 5).map((item) => (
                  <Link
                    to={`/movie/detail?code=${item?.id}`}
                    key={item?.id}
                    className="item"
                  >
                    <div className="thumb">
                      <img alt="" src={item?.thumb_url} />
                      <span className="time">{item?.time}</span>
                    </div>
                    <div className="right">
                      <p>{item?.name}</p>
                      <p>
                        <span>{item?.count} Lượt phát sóng</span>
                      </p>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
