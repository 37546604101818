import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";

import bankIcon from "assets/img/icon/bank.png";
import { Link, useNavigate } from "react-router-dom";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import Collapse from "_common/component/Collapse";
import { formatNumber } from "_common/utils/formatValue";
import classNames from "classnames";
import { NotificationManager } from "react-notifications";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faQuestionCircle,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { get } from "lodash";
import { PaymentApi } from "states/api/payment";
import InputPassword from "_common/component/InputPassword";

const WithdrawPage = observer(() => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    authStore: { user, user_wallet, general },
  } = useStores();

  const [amount, setAmount] = useState(0);
  const [wallet_password, setWalletPassword] = useState("");

  const { mutate: onSubmit } = useMutation(
    (variables) =>
      PaymentApi.deposit({
        params: {
          ...variables,
          amount,
          bank_method: "banking",
          wallet_password,
          token_id: 3,
          type: "withdraw",
        },
      }),
    {
      onSuccess: (res) => {
        setWalletPassword("");
        setAmount(0);
        queryClient.invalidateQueries(["get_profile"]);
        navigate(`/payment/deposit/success?money=${amount}`);
      },
      onError: (error) => {
        const errorMessage = error?.message ?? "Vui lòng thử lại!";
        NotificationManager.error(errorMessage);
      },
    }
  );

  const handleChangeInput = (event) => {
    const value = event?.target?.value;
    const format = value?.replaceAll(",", "")?.replaceAll(".", "");
    setAmount(Number(format));
  };

  const handleSubmit = () => {
    if (amount < Number(general?.min_withdraw || 0))
      return NotificationManager.error(
        `Vui lòng nhập số tiền lớn hơn ${formatNumber(
          general?.min_withdraw
        )}đVND`
      );
    if (amount > Number(general?.max_withdraw || 0))
      return NotificationManager.error(
        `Vui lòng nhập số tiền nhỏ hơn ${formatNumber(
          general?.max_withdraw
        )}đVND`
      );
    onSubmit();
    return null;
  };

  const handleSelectAll = () => {
    setAmount(user_wallet?.amount);
  };

  const handleChangePassword = (event) => {
    setWalletPassword(event?.target?.value);
  };

  return (
    <>
      <HeaderTitle
        title="Rút tiền"
        subTitle={
          <span onClick={() => navigate(`/histories/withdraw`)}>
            Lịch sử rút tiền
          </span>
        }
      />

      <div className="payment-container payment-withdraw-page">
        {get(user, "banks[0].bank_code") ? (
          <div to="/payment/bank/add" className="addBankCard">
            <span>
              {get(user, "banks[0].bank_code")} (
              {get(user, "banks[0].bank_number_display")})
            </span>
            <FontAwesomeIcon icon={faCheck} />
          </div>
        ) : (
          <Link to="/payment/bank/add" className="addBankCard">
            <FontAwesomeIcon icon={faPlus} />
            <span>Thêm thẻ ngân hàng</span>
          </Link>
        )}

        <div className="payMoney">
          <div className="label">
            <span>Số tiền rút (VND)</span>
          </div>
          <div className="money">
            <span>VND</span>
            <input
              inputMode="numeric"
              pattern="[0-9]"
              placeholder="Nhập số tiền"
              className="control-number"
              value={amount ? formatNumber(amount) : ""}
              onChange={handleChangeInput}
            />
            <span className="fieldMoney">K</span>
            <button
              type="button"
              className="btn btn-all"
              onClick={handleSelectAll}
            >
              Tất cả
            </button>
          </div>
          <div className="money" style={{ paddingTop: 20 }}>
            <span>Mật khẩu rút tiền</span>
            <InputPassword
              className="control-number"
              onChange={handleChangePassword}
            />
          </div>
          <div className="remark">
            <p>
              Số tiền rút mỗi lần lớn nhất {formatNumber(general?.max_withdraw)}
              KVND
            </p>
            <p>
              Số tiền rút mỗi lần nhỏ nhất {formatNumber(general?.min_withdraw)}
              KVND
            </p>
          </div>
          <div className="information">
            <div className="des-help">
              <div className="help-content">
                <FontAwesomeIcon icon={faQuestionCircle} />
                <span>Giới hạn mô tả</span>
              </div>
            </div>
            <div className="balance">
              <span>Số dư:</span>
              <span>{formatNumber(user_wallet?.amount)}</span>
              <span>VND</span>
            </div>
          </div>
        </div>
        <button
          type="button"
          className={classNames("btn btn-sub", {
            active: Number(amount || 0) >= Number(general?.min_withdraw || 0),
          })}
          onClick={handleSubmit}
        >
          Rút tiền ngay
        </button>
      </div>
    </>
  );
});

export default WithdrawPage;
