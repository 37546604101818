import React, { useState } from "react";
import { Outlet, useSearchParams } from "react-router-dom";

import MobileFooter from "../Footer/MobileFooter";

export default function MobileLayout() {
  const [toggleMenu, toggleCollapsed] = useState(false);
  const [params] = useSearchParams();

  return (
    <div className="mobile-layout">
      <Outlet />
      <MobileFooter />
    </div>
  );
}
