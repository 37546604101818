import React, { useEffect, useState } from "react";
import { get } from "lodash";
import { observer } from "mobx-react-lite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useStores } from "_common/hooks";
import { Link } from "react-router-dom";

import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import ModalUpdateAvatar from "./ModalUpdateAvatar";

const sexText = {
  male: "Nam",
  female: "Nữ",
  other: "Không xác định",
};

const InformationPage = observer(() => {
  const {
    authStore: { user, user_wallet },
  } = useStores();

  const [openModalAvatar, setModalAvatar] = useState();

  return (
    <>
      <HeaderTitle title="Thông tin cá nhân" />
      <div className="information-container">
        <div className="menu-items">
          <div className="item " onClick={() => setModalAvatar(true)}>
            <div className="left">Ảnh đại diện</div>
            <div className="right">
              <img className="avatar" src={user?.avatar_url} alt="" />
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </div>
          <Link to="/member/update-info?type=full_name" className="item ">
            <div className="left">Họ tên thật</div>
            <div className="right">
              <span>{user?.full_name || "Chưa cài đặt"}</span>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </Link>
          <Link to="/member/update-info?type=sex" className="item ">
            <div className="left"> Giới tính</div>
            <div className="right">
              <span>{sexText[user?.sex]}</span>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </Link>
          <Link to="/member/update-info?type=nick_name" className="item ">
            <div className="left">Tên hiển thị</div>
            <div className="right">
              <span>{user?.nick_name || "Chưa cài đặt"}</span>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </Link>
          <Link to="/payment/bank" className="item ">
            <div className="left"> Liên kết thông tin</div>
            <div className="right">
              <span>{get(user, "banks[0].bank_code") || "Không"}</span>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </Link>
          <div className="item ">
            <div className="left"> Số điện thoại</div>
            <div className="right">
              <span>{user?.phone_display}</span>
            </div>
          </div>
        </div>
      </div>
      {openModalAvatar && (
        <ModalUpdateAvatar
          open={openModalAvatar}
          onClose={() => setModalAvatar(false)}
        />
      )}
    </>
  );
});

export default InformationPage;
