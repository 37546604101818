import { isEmpty } from "lodash";
import qs from "query-string";

export const getQueryParams = (searchValue) => {
  let params = {};
  try {
    if (isEmpty(searchValue)) return {};
    const { query } = qs.parse(searchValue);
    if (isEmpty(query)) return {};
    params = JSON.parse(atob(query));
    if (isEmpty(params)) return params;
    const result = {};

    Object.keys(params).forEach((key, index) => {
      result[key] = decodeURIComponent(params[key]);
    });

    return result;
  } catch (error) {
    console.error(error);
    return params;
  }
};

export const insertUrlParam = (searchParams) => {
  if (window.history.pushState) {
    const newURL = `${window.location.protocol}//${window.location.host}${window.location.pathname}${window.location.hash}${searchParams}`;
    window.history.pushState({ path: newURL }, "", newURL);
  }
};

export const setQueryParams = (prevParams, property, value) => {
  try {
    const params = getQueryParams(prevParams);

    if (isEmpty(value)) {
      delete params[property];
    } else {
      if (!params[property]) {
        params[property] = {};
      }
      params[property] = value;
    }
    if (isEmpty(params)) return "";

    const result = {};

    Object.keys(params).forEach((key, index) => {
      result[key] = encodeURIComponent(params[key]);
    });

    return `?query=${btoa(JSON.stringify(result))}`;
  } catch (error) {
    console.error(error);
    return "";
  }
};

export const updateQueryParams = (params) => {
  try {
    const searchValue = window.location.search;

    const paramsString = getQueryParams(searchValue);

    let queryString = "";

    Object.keys(params).forEach((key, index) => {
      const value = params[key] ? `${params[key]}` : null;
      if (index === 0) {
        queryString = setQueryParams(paramsString, key, value);
      } else {
        queryString = setQueryParams(queryString, key, value);
      }
    });

    return queryString;
  } catch (error) {
    console.error(error);
    return "";
  }
};

export const formatQueryParams = (params) => {
  try {
    const searchValue = window.location.search;

    const paramsString = getQueryParams(searchValue);

    let queryString = "";

    Object.keys(params).forEach((key, index) => {
      const value = params[key] ? `${params[key]}` : null;
      if (index === 0) {
        queryString = setQueryParams(paramsString, key, value);
      } else {
        queryString = setQueryParams(queryString, key, value);
      }
    });

    return queryString;
  } catch (error) {
    console.error(error);
    return "";
  }
};
