import classNames from "classnames";
import React, { useEffect, useState } from "react";

import { Link, useLocation } from "react-router-dom";
import icon1 from "assets/img/icon/icon1.jpg";
import icon2 from "assets/img/icon/icon2.jpg";
import icon3 from "assets/img/icon/icon3.png";
import icon4 from "assets/img/icon/icon4.jpg";
import icon5 from "assets/img/icon/icon5.jpg";

export default function MobileFooter({ toggleMenu, toggleCollapsed, menus }) {
  const location = useLocation();
  const pathName = location?.pathname;

  const [openPayment, setOpenPayment] = useState(false);

  return (
    <div className="footer-mobile">
      <Link
        to="/"
        className={classNames("footer_item", {
          active: pathName === "/",
        })}
      >
        <img src={icon1} alt="Trang chủ" />
        <h5>Trang chủ</h5>
      </Link>
      <Link
        to="/game"
        className={classNames("footer_item", {
          active: pathName === "/game",
        })}
      >
        <img src={icon2} alt="Sảnh bình chọn" />
        <h5>Sảnh bình chọn</h5>
      </Link>
      <Link
        to="/beauty"
        className={classNames("footer_item main-img", {
          active: pathName === "/beauty",
        })}
      >
        <div className="img-mark">
          <img src={icon3} alt="Rạp chiếu phim" />
        </div>
      </Link>
      <Link
        to="/movie"
        className={classNames("footer_item", {
          active: pathName === "/movie",
        })}
      >
        <img src={icon4} alt="Rạp chiếu phim" />
        <h5>Rạp chiếu phim</h5>
      </Link>
      <Link
        to="/member"
        className={classNames("footer_item", {
          active: pathName === "/member",
        })}
      >
        <img src={icon5} alt="Của tôi" />
        <h5>Của tôi</h5>
      </Link>
    </div>
  );
}
