import React from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useStores } from "_common/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDots } from "@fortawesome/free-solid-svg-icons";

function HeaderTop({ isBack = true, title }) {
  const {
    authStore: { general },
  } = useStores();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return (
    <div className="home-top-bar">
      <div className="logo-div">
        <img className="logo-img" src={general?.logo} alt="" />
        <FontAwesomeIcon icon={faCommentDots} />
      </div>
      <div className="server-msg-div" />
    </div>
  );
}

export default HeaderTop;
