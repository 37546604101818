import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useStores } from "_common/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useQuery } from "@tanstack/react-query";
import { MovieApi } from "states/api/movie";

export default function ListRoom() {
  const navigate = useNavigate();

  const { data, refetch } = useQuery(
    ["movie", "list_home"],
    () =>
      MovieApi.getList({
        params: {
          page: 1,
          limit: 14,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  return (
    <div className="hot-recommend">
      <div className="hot-title">
        <h2>Đề xuất phổ biến</h2>
        <div className="action">
          <span onClick={() => navigate("/movie")}>Xem thêm</span>
          <FontAwesomeIcon icon={faAngleRight} />
        </div>
      </div>
      <div className="list-video">
        {data?.data?.map((item) => (
          <Link
            to={`/movie/detail?code=${item?.id}`}
            key={item?.id}
            className="item"
          >
            <img alt="" src={item?.thumb_url} />
            <p className="movie-time">
              {item?.name}
              <span className="count-down">Chơi: {item?.code}</span>
            </p>
          </Link>
        ))}
      </div>
      <div className="btn-group">
        <button
          type="button"
          className="btn btn-more"
          onClick={() => navigate("/movie")}
        >
          Xem thêm
        </button>
      </div>
    </div>
  );
}
