import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useStores } from "_common/hooks";
import { NotificationManager } from "react-notifications";
import { GirlApi } from "states/api/girl";
import { useQuery } from "@tanstack/react-query";

const BeautyDetailPage = observer(() => {
  const navigate = useNavigate();
  const {
    authStore: { girls },
  } = useStores();

  const [params] = useSearchParams();
  const code = params.get("code");

  const { data, refetch } = useQuery(
    ["girl", "list", code],
    () =>
      GirlApi.getDetail({
        id: code,
      }),
    {
      staleTime: 300000,
    }
  );

  const handleSubmit = () => {
    //
    NotificationManager.warning("Tiếp tục xem liên lạc thầy");
  };

  return (
    <>
      <HeaderTitle title="THIÊN ĐỊA MỸ NHÂN" />
      <div className="main-content">
        <div className="page-content">
          <div className="beauty-container">
            <div className="van-tabs">
              <div className="van-tabs__nav">
                <div
                  className={classNames("van-tab")}
                  onClick={() => navigate(`/beauty/city`)}
                >
                  <p>Tỉnh/Thành Phố</p>
                </div>
                <div
                  className={classNames("van-tab")}
                  onClick={() => navigate(`/beauty?tab=tab2`)}
                >
                  <p>Giới thiệu nền tảng</p>
                </div>
              </div>
            </div>
            <div className="beauty-detail-container">
              <img src={data?.image_url} alt="" />
              <button
                type="button"
                className={classNames("btn btn-next")}
                onClick={handleSubmit}
              >
                Bước tiếp theo
              </button>
              {data?.next_girl && (
                <h5>
                  Kế tiếp:{" "}
                  {data?.next_girl?.code || `A00${data?.next_girl?.id}`}
                </h5>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default BeautyDetailPage;
