import React, { useEffect, useState } from "react";

export default function Intro() {
  return (
    <div className="intro-container">
      <h5>Có những nguồn tài nguyên nào?</h5>
      <p>
        Người nỗi tiếng diễn viên, học sinh,sinh viên, những cô gái trẻ tuổi,
        mc,giáo viên... nền tảng bên em sẽ hỗ trợ sắp xếp cho các bạn cách liên
        lạc gặp mặt,hẹn hò và vui vẻ cùng nhau,nhưng có điều bạn không thể liên
        hệ được nếu không thông qua app hẹn hò này.
      </p>
      <h5>Quy mô của APP?</h5>
      <p>
        Các cuộc hẹn đều không thu phí trong cùng 1 tỉnh thành và bất kỳ huyện
        hoặc xã nào trong cùng 1 thành phố đều có thể liên hệ đặt lịch hẹn thông
        qua tư vấn viên.
      </p>
      <h5>
        Để yêu cầu thêm khu vực để đáp ứng nhu cầu hẹn hò của các thành phố
        khác, vui lòng liên hệ với chuyên viên.
      </h5>
    </div>
  );
}
