import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";

import { Link, useNavigate } from "react-router-dom";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { BankApi } from "states/api/bank";
import { get } from "lodash";

const BankPage = observer(() => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    authStore: { user, user_wallet, general },
  } = useStores();

  const { data, refetch } = useQuery(
    ["bank", "list"],
    () =>
      BankApi.getList({
        params: {
          page: 1,
          limit: 10,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <>
      <HeaderTitle title="Liên kết thông tin" />

      <div className="payment-container bank-page">
        {get(data, "data[0].bank_code") ? (
          <div className="card-info">
            <p>{get(data, "data[0].bank_code")}</p>
            <p>{get(data, "data[0].bank_owner")}</p>
            <p>{get(data, "data[0].bank_number")}</p>
          </div>
        ) : (
          <Link to="/payment/bank/add" className="add-card">
            <FontAwesomeIcon icon={faPlus} />
            <span>Thêm thẻ ngân hàng</span>
          </Link>
        )}

        <div className="tip">
          Gợi ý:Vui lòng liên kết ngân hàng thương mại lớn, Nếu cần sửa đổi,vui
          lòng liên hệ dịch vụ khách hàng trực tuyến.
        </div>
      </div>
    </>
  );
});

export default BankPage;
