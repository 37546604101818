import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import ListVideo from "./ListVideo";

const tabs = {
  new: "Mới nhất",
  hot: "Hot nhất",
  recommend: "Đề xuất",
  video1: "Nội địa",
  video2: "Madou",
  video3: "Live streaming",
  video4: "Châu Á",
  video5: "Anime",
  video6: "Châu Âu và Châu Mỹ",
};

const MoviePage = observer(() => {
  const [active_tab, setActiveTab] = useState("new");
  return (
    <>
      <HeaderTitle title=" Rạp chiếu phim" isBack={false} />
      <div className="main-content">
        <div className="page-content">
          <div className="video-container">
            <div className="van-tabs">
              <div className="van-tabs__nav">
                {Object.keys(tabs).map((key) => (
                  <div
                    key={key}
                    className={classNames("van-tab", {
                      active: active_tab === key,
                    })}
                    onClick={() => setActiveTab(key)}
                  >
                    <p>{tabs[key]}</p>
                  </div>
                ))}
              </div>
            </div>
            <ListVideo type={active_tab} />
          </div>
        </div>
      </div>
    </>
  );
});

export default MoviePage;
