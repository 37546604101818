import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";

import bankIcon from "assets/img/icon/bank.png";
import { Link, useNavigate } from "react-router-dom";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import { NotificationManager } from "react-notifications";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { BankApi } from "states/api/bank";
import { optionBank } from "_common/constants/bank";

const schema = yup
  .object({
    bank_owner: yup.string().required("Bắt buộc điền"),
    bank_number: yup.string().required("Bắt buộc điền"),
    bank_code: yup.string().required("Bắt buộc điền"),
  })
  .required();

const BankAddPage = observer(() => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    authStore: { user, user_wallet, general },
  } = useStores();

  const [select_bank, setSelectBank] = useState();

  const { mutate, isLoading } = useMutation(
    (variables) => BankApi.create({ params: variables }),
    {
      onSuccess: (res) => {
        navigate("/");
        NotificationManager.success(res?.msg || "Thao tác thành công");
      },
      onError: (error) => {
        const errorMessage = error?.message ?? "Vui lòng thử lại!";
        NotificationManager.error(errorMessage);
      },
    }
  );

  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (!user) return;
    setValue("bank_owner", user?.full_name);
  }, [user]);

  const onSave = (values) => {
    if (isLoading) return;
    trigger();
    const formValue = getValues();
    const isValid = schema.isValidSync(formValue);
    if (isValid) {
      const bank = optionBank.find((obj) => obj.value === values?.bank_code);

      mutate({
        ...values,
        bank_name: bank?.text,
      });
    }
  };

  return (
    <>
      <HeaderTitle title="Liên kết thẻ ngân hàng" />

      <div className="payment-container bank-add-page">
        <form
          className="form-action form-horizontal"
          onSubmit={handleSubmit(onSave)}
        >
          <div className="label">Vui lòng nhập thông tin cá nhân của bạn</div>
          <div
            className={classNames("form-item", {
              error: errors.bank_owner,
            })}
          >
            <div htmlFor="bank_owner" className="form_label">
              Họ tên thật
            </div>
            <div className="form_field">
              <input
                className="field_control"
                type="text"
                id="bank_owner"
                placeholder="Mời nhập"
                {...register("bank_owner")}
              />
              <span className="error_t">{errors.bank_owner?.message}</span>
            </div>
          </div>
          <div className="line" />
          <div className="label">
            Vui lòng nhập thông tin thẻ ngân hàng của bạn
          </div>
          <div
            className={classNames("form-item", {
              error: errors.bank_number,
            })}
          >
            <div htmlFor="bank_number" className="form_label">
              Số tài khoản ngân hàng
            </div>
            <div className="form_field">
              <input
                className="field_control"
                type="text"
                id="bank_number"
                placeholder="Mời nhập"
                {...register("bank_number")}
              />
              <span className="error_t">{errors.bank_number?.message}</span>
            </div>
          </div>
          <div
            className={classNames("form-item", {
              error: errors.bank_code,
            })}
          >
            <div htmlFor="bank_code" className="form_label">
              Tên ngân hàng
            </div>
            <div className="form_field">
              <select
                placeholder="Vui lòng chọn"
                className="field_select"
                id="bank_code"
                {...register("bank_code")}
                value={select_bank}
              >
                <option value="">Vui lòng chọn</option>
                {optionBank.map((item) => (
                  <option key={item?.value} value={item?.value}>
                    {item.text} ({item.sortName})
                  </option>
                ))}
              </select>
              <span className="error_t">{errors.bank_code?.message}</span>
            </div>
          </div>
          <p className="text-red">
            Khách hàng thân ái,để bảo vệ sự an toàn cho tài sản của bạn,Vui lòng
            liên kết tên thật và cài đặt mật khẩu rút tiền,Nếu tên không khớp
            với tên tài khoản,sẽ không thể rút tiền.
          </p>
          <button
            type="submit"
            className={classNames("btn btn-sub", {
              active: true,
            })}
          >
            Xác nhận liên kết thẻ
          </button>
        </form>
      </div>
    </>
  );
});

export default BankAddPage;
