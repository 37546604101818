import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import ListBanner from "./ListBanner";
import Notice from "./Notice";
import HotGame from "./HotGame";
import HotVideo from "./HotVideo";
import ListRoom from "./ListRoom";

const Home = observer(() => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <>
      <HeaderTop />
      <div className="main-content">
        <div className="page-content">
          <ListBanner />
          <Notice />
          <HotGame />
          <HotVideo />
          <ListRoom />
        </div>
      </div>
    </>
  );
});

export default Home;
