import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { getQueryParams } from "_common/utils/queryParams";
import { useMutation } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import { NotificationManager } from "react-notifications";

export const MiddlewareRouter = observer(({ children }) => {
  const { authStore } = useStores();
  const location = useLocation();
  const navigate = useNavigate();

  const filters = useMemo(
    () => getQueryParams(location?.search) || {},
    [location]
  );

  const { mutate, isLoading } = useMutation(
    (variables) => AuthApi.checkLogin(variables),
    {
      onSuccess: (res) => {
        authStore.updateToken(res?.data?.token);
        authStore.updateUser(res?.data?.user);
        setTimeout(() => {
          navigate("/");
        }, 200);
      },
      onError: (error) => {
        const errorMessage = error?.message ?? "Vui lòng thử lại!";
        NotificationManager.error(errorMessage);
      },
    }
  );

  useEffect(() => {
    if (!filters?.user_id) return;
    if (authStore.authenticated) return;
    mutate(filters);
  }, [filters?.user_id, authStore]);

  if (authStore.device && !authStore.authenticated && !filters?.user_id) {
    return <Navigate to="/auth/login" state={{ from: location }} replace />;
  }

  return <div>{children}</div>;
});
